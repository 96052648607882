<template>
  <b-container class="full-height">
    <b-row style="display: flex; flex-direction: column; align-items: center; justify-content: center">
      <b-col cols="12" md="6">
        <b-card>
          <div class="d-flex flex-column align-item-center justify-content-center">
            <img :src="daikinLogo" class="warranty-logo"/>

            <h4>This certificate number ( {{ cert }} ) is a project warranty.</h4>
            <h4>Please go to Project Warranty module to print this certificate.</h4>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  data() {
    return {
      daikinLogo: require('@/assets/images/drcc/daikin-logo.png'),
    }
  },
  computed: {
    cert() {
      return this.$route.query.cert;
    }
  }
  
}

</script>

<style>
.warranty-logo {
  width: 70%;
  height: auto;
  margin: 0 auto 15px auto;
}
.full-height {
  min-height: 80vh; /* Ensures the container takes at least 100% of the viewport height */
  display: flex;
  flex-direction: column;
}
</style>
